import "./VeeValidate";
import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { AlertComponentInterface } from "../interfaces";
import StatefulButton from "./../StatefulButton.vue";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    StatefulButton,
  },
})
export class BaseForm extends Vue {
  @Prop({ required: false })
  public readonly action!: string;

  @Ref("formObserver")
  public readonly $formObserver!: InstanceType<typeof ValidationObserver>;

  @Ref("alert")
  public readonly $alert!: AlertComponentInterface;

  public disabled = false;
  public busy = false;
  public hasLoader = false;

  public hasError = false;

  public handleError(response: any) {
    const error = response.error;
    if (this.hasError) {
      this.$alert.clear();
    }
    this.hasError = true;
    this.$alert.addAlert({
      type: "danger",
      message: error.message || "An error occured",
    });
    if (response.validationFailed) {
      // do something
      const errors = response.validationErrors;
      this.$formObserver.setErrors(errors);
    }
  }

  public async send() {
    return;
  }

  public async submit() {
    this.busy = true;
    if (this.hasError) {
      this.$alert.clear();
    }
    try {
      await this.send();
    } catch (error) {
      this.$alert.addAlert({
        type: "danger",
        message: error.message || "An error occured",
      });
    } finally {
      this.busy = false;
    }
  }
}
