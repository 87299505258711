













import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class StatefulButtonComponent extends Vue {
  @Prop({ default: false })
  busy!: boolean;
}
